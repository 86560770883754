<template>
  <div class="access-permission-container">
    <vs-row class="mt-5">
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-medium">Users</h3>
          </div>
        </div>
        <div class="flex justify-end">
          <vs-button class="only-border-btn" size="medium" v-round @click="$router.push({ name: 'partner-users-create' })">
            <plus-icon size="1.5x" class="button-icon"></plus-icon>New user
          </vs-button>
        </div>
        <vs-table :max-items="descriptionItems[0]" :data="users" class="search-left stripes mt-8">
          <template slot="thead">
            <vs-th class="text-left">Name</vs-th>
            <vs-th>Role</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Phone</vs-th>
            <vs-th></vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(user, indextr) in data">
              <vs-td :data="user.fullName">
                {{ user.fullName }}
              </vs-td>
              <vs-td :data="user.userType">
                <span v-if="user.userType == 'admin'">Super Admin</span>
                <span v-else-if="user.userType == 'staffAdmin'">Admin</span>
                <span v-else-if="user.userType == 'staff'">User</span>
              </vs-td>
              <vs-td :data="user.email">
                {{ user.email ? user.email : "N/A" }}
              </vs-td>
              <vs-td :data="user.phoneNumber">
                {{ user.phoneNumber ? user.phoneNumber : "N/A" }}
              </vs-td>
              <vs-td :data="user">
                <a @click="sendCredentials(user._id)">
                  <u>{{ user.credentialsSent ? "Resend credentials" : "Send credentials" }}</u>
                </a>
              </vs-td>
              <vs-td :data="user._id">
                <a @click="editUser(user._id)"><u>Edit</u></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { EditIcon, PlusIcon } from "vue-feather-icons";

export default {
  components: { EditIcon, PlusIcon },
  data() {
    return {
      descriptionItems: [20, 25, 40],
      users: [],
    };
  },
  methods: {
    ...mapActions("partner", ["fetchStaffByPartnerId", "sendCredentialsToStaff"]),

    async sendCredentials(id) {
      await this.sendCredentialsToStaff(id).then((result) => {
        this.$vs.notify({
          title: "Success",
          text: "The username and password has been successfully sent!",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });

        this.getUsers(this.partnerId);
      }).catch((ex) => {
        this.$vs.notify({
          title: "Error",
          text: "Error sending user credentials",
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-times-circle",
        });
      });
    },

    // functions of users
    async getUsers(partnerId) {
      await this.fetchStaffByPartnerId(partnerId).then((result) => {
        this.users = result.data.data;
      }).catch((ex) => {
        if (ex.status == 400) {
          this.$vs.notify({
            title: "Staff",
            text: err.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        }
      });
    },

    editUser(id) {
      this.$router.push({
        name: "partner-users-edit",
        params: { id: id },
      });
    },
  },

  mounted() {
    this.getUsers(this.partnerId);
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
  },
};
</script>

